.actionsContainer {
  display: flex;
}

.actionButton {
  font-size: 20px;
}

.rolePageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.title {
  font-weight: 400;
}

.searchInput {
  width: 300px;
  margin-right: 15px;
}

.formInput {
    margin-bottom: 30px;
    margin-top: 5px;
}

.permissionName {
  margin-left: 10px;
}

.permissionContainer {
  display: flex;
}

.permissionCategoryContainer {
  display: flex;
  flex-direction: column;
}

.permissionCategoryCheckbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.permissionDescription {
  margin-left: 10px;
}
