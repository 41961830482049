.generalSectionsContainer {
    padding: 24px;
    margin-bottom: 1rem;
  }
  
.notificationsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}
  
.formContainer {
    max-width: 600px;
}
.formSection {
    margin-bottom: 32px;
}
.formTitle {
    margin-bottom: 16px !important;
}